import React from 'react'

const RowDetails = ({ name, value, isDesc = false }) => {
    return (
        <>
            {value && <div className="col-4 my-2">
                <label className="form-label">{name} </label>
                {!isDesc ?
                    <input

                        disabled defaultValue={value}


                        required autoComplete="off" name="Name" type="text" className="form-control" /> :
                    <textarea

                        disabled defaultValue={value}

                        rows="3"
                        required autoComplete="off" name="Name" type="text" className="form-control" />
                }
                <span asp-validation-for="Name" className="text-danger" />
            </div>}




        </>
    )
}

export default RowDetails
