import { useEffect, useState } from "react";

import useLoader from "../../Components/loader/useLoader";
import { baseurl } from "../../constants";
import UpdateButton from "../../Components/Buttons/UpdateButton";
import DeleteButton from "../../Components/Buttons/DeleteButton";
import { createBrannd, createModel, deleteModel } from "../Core/Rotes";
import { useLocation, useNavigate } from "react-router-dom";
import TextButton from "../../Components/Buttons/TextButton";
var count;
var search = "";


export default function ModelsCar() {
    const [data, setData] = useState();
    const [pagination, setPagination] = useState({});
    const [brands, setBrands] = useState();
    const [loader, showLoader, hideLoader] = useLoader();


    const navigate = useNavigate();





    const getData = async (brandId) => {

        showLoader();


        try {
            const response = await fetch(baseurl + "BrandType/get-BrandType-by-brandId?brandId=" + brandId, {
                method: "GET",
                // headers: {
                //     Authorization: "Bearer " + localStorage.getItem("token"),
                // },


            });
            const json = await response.json();
            hideLoader();


            console.log(json);
            setData(json);
            return json;
        } catch (error) {
            hideLoader();
            console.error(error + "kjjjjjjj");
        }
    };



    useEffect(() => {
        getBrands();
        if (!data) {
            getData(0);
        }
    }, []);


    // ! get brands 

    const getBrands = async () => {


        try {
            const response = await fetch(baseurl + "BrandCars/get-BrandCars", {
                method: "GET",
                // headers: {
                //     Authorization: "Bearer " + localStorage.getItem("token"),
                // },


            });
            const json = await response.json();
            hideLoader();

            console.log(json);
            setBrands(json);
            return json;
        } catch (error) {
            hideLoader();
            console.error(error + "kjjjjjjj");
        }
    }


    return (
        <div>
            {loader}

            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="breadcrumb-title pe-3">المودل </div>
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item">
                                <a href="javascript:;">
                                    <i className="bx bx-home-alt" />
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                المودل
                            </li>
                        </ol>
                    </nav>
                </div>

            </div>
            {/*end breadcrumb*/}
            <hr />

            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <div
                            id="example2_wrapper"
                            className="dataTables_wrapper dt-bootstrap5"
                        >
                            <div className="row">
                                <div className="col-sm-12">
                                    <p></p>
                                    <div className="row justify-content-between">
                                        <div className="col-12 col-md-4">
                                            <div className="card-body row no-gutters align-items-start">
                                                {/*end of col*/}
                                                <div className="col">
                                                    <input
                                                        autoComplete="off"
                                                        onChange={(e) => (search = e.target.value)}
                                                        name="SearchData"
                                                        className="form-control form-control form-control-borderless"
                                                        type="search"
                                                        placeholder="بحث في القائمة"
                                                    />
                                                </div>
                                                {/*end of col*/}
                                                <div className="col-auto">
                                                    <button
                                                        onClick={(e) => getData(1, search)}
                                                        className="btn btn btn-primary"
                                                        type="submit"
                                                    >
                                                        بحث
                                                    </button>
                                                </div>
                                                {/*end of col*/}
                                            </div>
                                        </div>
                                        {/*end of col*/}

                                    </div>
                                    {/* <p /> */}
                                    <div className="col-4 m-3">
                                        <label className="form-label">اختر الماركة   </label>
                                        {brands && (
                                            <select

                                                required
                                                // name="ServiceId"
                                                onChange={async (value) => {



                                                    await getData(value.target.value)



                                                }}
                                                id="school-id"
                                                className="form-select"
                                            >
                                                <option value={""}>اختر</option>
                                                {brands.map((row) => (
                                                    <option key={row.id} value={row.id}>
                                                        {row.nameAr}
                                                    </option>
                                                ))}
                                            </select>
                                        )}

                                        <span
                                            asp-validation-for="FieldId"
                                            className="text-danger"
                                        />
                                    </div>

                                    <table
                                        id="DataTable"
                                        className="table table-striped table-bordered dataTable"
                                        role="grid"
                                        aria-describedby="example2_info"
                                    >
                                        <thead>
                                            <tr role="row">
                                                <th
                                                    className="sorting_asc text-center align-middle"
                                                    tabIndex={0}
                                                    aria-controls="example2"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-sort="ascending"
                                                    aria-label="Name: activate to sort column descending"
                                                    style={{ width: "58.828px" }}
                                                >
                                                    <span className="mx-3">م</span>
                                                </th>
                                                <th
                                                    className="sorting_asc text-center align-middle"
                                                    tabIndex={0}
                                                    aria-controls="example2"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-sort="ascending"
                                                    aria-label="Name: activate to sort column descending"
                                                    style={{ width: "120.828px" }}
                                                >
                                                    {" "}
                                                    الاسم باللغة العربية{" "}
                                                </th>

                                                <th
                                                    className="sorting_asc text-center align-middle"
                                                    tabIndex={0}
                                                    aria-controls="example2"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Position: activate to sort column ascending"
                                                    style={{ width: "120.891px" }}
                                                >
                                                    الاسم باللغة الانجليزية{" "}
                                                </th>
                                                <th
                                                    className="sorting text-center align-middle"
                                                    tabIndex={0}
                                                    aria-controls="example2"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Position: activate to sort column ascending"
                                                    style={{ width: "100.891px" }}
                                                >
                                                    صورة{" "}
                                                </th>






                                                <th
                                                    className="sorting text-center align-middle"
                                                    tabIndex={0}
                                                    aria-controls="example2"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Office: activate to sort column ascending"
                                                    style={{ width: "80.312px" }}
                                                >
                                                    تعديل
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {data &&
                                                data.map((item, index) => (
                                                    <tr role="row" className="odd">
                                                        {/* <span className="mx-3">{rowIndex(index)}</span> */}
                                                        <td className="text-center align-middle">
                                                            {item.id}
                                                        </td>
                                                        <td className="text-center align-middle">
                                                            {item.nameAr}
                                                        </td>
                                                        <td className="text-center align-middle">
                                                            {item.nameEng}
                                                        </td>
                                                        {/* <td>{item.addressName}</td> */}
                                                        <td className="text-center align-middle">
                                                            <div className=" gap-3 cursor-pointer">
                                                                <img
                                                                    className="rounded-circle"
                                                                    src={
                                                                        item.logo != null
                                                                            ? baseurl +
                                                                            "images/" +
                                                                            item.logo
                                                                            : ""
                                                                    }
                                                                    // className="rounded-circle"
                                                                    width={64}
                                                                    height={64}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </td>




                                                        <td className="text-center align-middle">


                                                            <UpdateButton icon="bi bi-pencil-fill" onClick={() => {

                                                                navigate(createModel, { state: { row: item } });
                                                            }} />

                                                            <DeleteButton icon="bi bi-trash-fill" onClick={() => {
                                                                navigate(deleteModel, { state: { row: item } });
                                                            }} />






                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                    <br />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
