import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { baseurl, orderStatues, uploadImage } from "../constants";
import useLoader from "../Components/loader/useLoader";
import Switch from "@mui/material/Switch";
import DeleteButton from "../Components/Buttons/DeleteButton";
import CustomButton from "../Components/Buttons/DeleteButton";
import ViewButton from "../Components/Buttons/ViewButton";
import UpdateButton from "../Components/Buttons/UpdateButton";
import { services } from "../Helpers/Data";
import { formatDateToText, getServices, listStatusOrder } from "../Helpers/Utilities";
import { Button } from "@mui/material";
import { deleteOrder, orderDetails, orders } from "./Core/Rotes";
import DeleteOrder from "./Orders/Delete";

export default function Home() {
  const [data, setData] = useState();
  const [loader, showLoader, hideLoader] = useLoader();
  const [pagination, setPagination] = useState({});
  //! navigation
  const navigate = useNavigate();


  //** get data */
  const getData = async () => {
    showLoader();
    try {
      const response = await fetch(
        baseurl + "dashboard/home",
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      const json = await response.json();
      console.log(json);
      hideLoader();
      setData(json);

      return json;
    } catch (error) {
      console.error(error);
    }
  };

  const rowIndex = (index) => {
    return (pagination["pageNo"] - 1) * 8 + (index + 1);
  };

  //** update provider */

  //** update product */
 // ! update status
 const updateStatus = async (orderId, status) => {
  showLoader();
  try {
      var formdata = new FormData();
      formdata.append("orderId", orderId);
      formdata.append("status", status);

      const response = await fetch(baseurl + "dashboard/update-status-order", {
          body: formdata,
          method: "POST",

          headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
          },
      });
      const json = await response.json();
      console.log(json);
    
      hideLoader();
      // setData(json);
      return json;
  }

  catch (error) {
      hideLoader();
      console.error(error + "errrrrrrrr");
  }
};

  useEffect(() => {
    getData();

  }, []);

  return (
    <>
      {loader}
      {data && (
        <>
          <div class="row row-cols-1 text-white row-cols-lg-2 row-cols-xl-4">
            <div class="col">
              <div class="card rounded-4  bg-primary">
                <div class="card-body ">
                  <div class="d-flex align-items-center">
                    <div class="">
                      <p class="mb-1">الخدمات</p>
                      <h4 class="mb-0">{data.servicesCount}</h4>
                      <p class="mb-0 mt-2 font-13">
                        <i class="bi bi-arrow-up"></i>
                        <span>عدد الخدمات : {data.servicesCount}</span>
                      </p>
                    </div>
                    <div class="ms-auto widget-icon bg-white text-primary">
                      <i class="bi bi-grid-fill"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card bg-success rounded-4">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="">
                      <p class="mb-1">الطلبات</p>
                      <h4 class="mb-0">{data.orders}</h4>
                      <p class="mb-0 mt-2 font-13">
                        <i class="bi bi-arrow-up"></i>
                        <span>عدد الطلبات : {data.orders}</span>
                      </p>
                    </div>
                    <div class="ms-auto widget-icon bg-white text-success">
                      <i class="bi bi-basket2-fill"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card bg-orange rounded-4">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="">
                      <p class="mb-1">المستخدمين</p>
                      <h4 class="mb-0">{data.users}</h4>
                      <p class="mb-0 mt-2 font-13">
                        <i class="bi bi-arrow-up"></i>
                        <span>عدد المستخدمين : {data.users} </span>
                      </p>
                    </div>
                    <div class="ms-auto widget-icon bg-white text-orange">
                      <i class="bi bi-award-fill"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card bg-secondary rounded-4">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="">
                      <p class="mb-1">المتاجر</p>

                      <h4 class="mb-0">{data.markets}</h4>
                      <p class="mb-0 mt-2 font-13">
                        <i class="bi bi-arrow-up"></i>
                        <span>عدد المتاجر : {data.markets}</span>
                      </p>
                    </div>
                    <div class="ms-auto widget-icon bg-white text-secondary">
                      <i class="bx bxs-package"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <div className="row p-3 m-1 ">


                <div className="home-footer">
                  <div>
                    <div className="container" >
                      <div className="row justify-content-between">
                        <div className="col">
                          <h3 className="home-order-header">
                            {" "}
                            الطلبات الجديدة
                          </h3>
                        </div>

                        <div className="col-2">
                          <button onClick={() => navigate(orders)}  style={styles.button}>
                            See All
                          </button>
                        </div>
                      </div>



                    </div>
                    {/* //* providers */}
                    <div>
                    <table
                                        id="DataTable"
                                        className="table table-striped table-bordered dataTable"
                                        role="grid"
                                        aria-describedby="example2_info"
                                    >
                                        <thead>
                                            <tr role="row">
                                                <th
                                                    className="sorting_asc text-center align-middle"
                                                    tabIndex={0}
                                                    aria-controls="example2"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-sort="ascending"
                                                    aria-label="Name: activate to sort column descending"
                                                    style={{ width: "25.828px" }}
                                                >
                                                    <span className="mx-3">#</span>
                                                </th>

                                                <th
                                                    className="sorting text-center align-middle"
                                                    tabIndex={0}
                                                    aria-controls="example2"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Position: activate to sort column ascending"
                                                    style={{ width: "50.891px" }}
                                                >
                                                    اسم العميل 
                                                </th>
                                                <th
                                                    className="sorting text-center align-middle"
                                                    tabIndex={0}
                                                    aria-controls="example2"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Position: activate to sort column ascending"
                                                    style={{ width: "50.891px" }}
                                                >
                                                   رقم الهاتف
                                                </th>

                                                <th
                                                    className="sorting text-center align-middle"
                                                    tabIndex={0}
                                                    aria-controls="example2"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Position: activate to sort column ascending"
                                                    style={{ width: "60.891px" }}
                                                >
                                                    التاريخ
                                                </th>


                                                <th
                                                    className="sorting text-center align-middle"
                                                    tabIndex={0}
                                                    aria-controls="example2"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Position: activate to sort column ascending"
                                                    style={{ width: "50.891px" }}
                                                >
                                                    حالة الطلب
                                                </th>

                                                <th
                                                    className="sorting text-center align-middle"
                                                    tabIndex={0}
                                                    aria-controls="example2"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Position: activate to sort column ascending"
                                                    style={{ width: "60.891px" }}
                                                >
                                                    تعديل
                                                </th>

                                                <th
                                                    className="sorting text-center align-middle"
                                                    tabIndex={0}
                                                    aria-controls="example2"
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label="Office: activate to sort column ascending"
                                                    style={{ width: "50.312px" }}
                                                >
                                                    فعل
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {data &&
                                                data.latestOrders.map((item, index) => (
                                                    <tr role="row" className="odd">
                                                        {/* <span className="mx-3">{rowIndex(index)}</span> */}
                                                        <td className="text-center align-middle">
                                                            {item.order.id}
                                                        </td>
                                                        <td className="text-center align-middle">
                                                            {item.user&&item.user.firstName+" ," + item.user.lastName}
                                                        </td>
                                                        <td className="text-center align-middle">
                                                            {item.user&&item.user.phoneNumber}
                                                        </td>
                                                        <td className="text-center align-middle">
                                                            {formatDateToText(item.order.createdAt)}
                                                        </td>
                                                        <td className="text-center align-middle">
                                                            {listStatusOrder[item.order.status].name}
                                                        </td>



                                                        <td className="text-center align-middle m-1 ">
                                                            
                                                                <select

                                                                    required
                                                                    // name="ServiceId"
                                                                    onChange={async (value) => {
                                                                        //! update order status
                                                                        console.log(value.target.value +"wefwwvefd");
                                                                        item.status = value.target.value
                                                                   await   updateStatus(item.order.id, value.target.value);

                                                                    }}
                                                                    id="status-id"
                                                                    className="form-select"
                                                                >
                                                                    <option value={""}>تعديل حالة الطلب </option>
                                                                    {listStatusOrder.map((row) => (
                                                                        <option key={row.id} value={row.id}>
                                                                            {row.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            
                                                        </td>
                                                        <td className="text-center align-middle">

                                                            <DeleteButton icon="far fa-trash-alt" onClick={() => {

                                                                // print 
                                                                navigate(deleteOrder, { state: { row: item } });
                                                            }} />

                                                            <ViewButton icon="fas fa-eye" onClick={() => {
                                                                
                                                                // nave to order details
                                                                console.log("view");
                                                                navigate(orderDetails, { state: { row: item.order } });

                                                            }} />

                                                            {/* <UpdateButton icon="fas fa-edit" onClick={() => {
                                                                // print 
                                                                console.log("update");
                                                            }} /> */}

                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                    </div>
                    {/* <PieChartView data={data.lastProviders} /> */}
                  </div>
                </div>


              </div>
            </div>
          </div>
        </>
      )}
      
    </>
    
  );
  
}
const styles = {
  button: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};
