
import React, { Component } from 'react';

export class DeleteButton extends Component {
    render() {
        const { icon, onClick } = this.props;
        return (
            <button className="btn btn-danger m-1 text-center" onClick={onClick}>
                <i className={icon} />
            </button>
        );
    }
}

export default DeleteButton;



