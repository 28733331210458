
import { format, parseISO } from 'date-fns';
import { baseurl } from '../constants';
import { services } from './Data';




export function formatDateToText(dateString) {
  try {
    const date = parseISO(dateString);
    return format(date, 'd/MM/yyyy - h:mm a');
  } catch (error) {
    console.error("Invalid Date Format", error);
    return "Invalid Date";
  }
}

// ! get services 
export async function getServices() {
  try {
    const response = await fetch(baseurl + "servies/get-Services", {
      method: "GET",
    });
    const json = await response.json();
    console.log(json + "services");
    return json;
  } catch (error) {
    console.error(error);
  }
}


/* 
 OrderStutsModel(
      nameAr: "تم الاستلام",
      nameEn: "Received",
      value: 0,
      icon: "assets/images/recevied.png"),
  OrderStutsModel(
      nameAr: "تم الشحن",
      nameEn: "Shipped",
      value: 1,
      icon: "assets/images/charges.png"),
  OrderStutsModel(
      nameAr: "تم وصول ميناء الترانزيت",
      nameEn: "Tranzit has arrived in the origin Country",
      value: 2,
      icon: "assets/images/arrived.png"),

        OrderStutsModel(
      nameAr:"تم وصول ميناء بلد المقصد",
      nameEn: "Country of destination has arrived",
      value: 3,
      icon: "assets/images/arrived_country.png"),
  OrderStutsModel(
      nameAr: "تم التخليص الجمركي",
      nameEn: "Customs cleared",
      value: 4,
      icon: "assets/images/done.png"),
  OrderStutsModel(
      nameAr: "تم تسليم العميل",
      nameEn: "Customer Delivered",
      value: 5,
      icon: "assets/images/delivared.png"),

*/

export const listStatusOrder = [
  { id: 0, name: "تم الاستلام" },
  { id: 1, name: "تم الشحن" },
  { id: 2, name: "تم وصول ميناء الترانزيت" },
  { id: 3, name: "تم وصول ميناء بلد المقصد" },
  { id: 4, name: "تم التخليص الجمركي" },
  { id: 5, name: "تم تسليم العميل" },
  { id: 6, name: "تم الغاء الطلب" },
]

export const listStatusOrderFurniture = [
  { id: 0, name: "تم الاستلام" },

  { id: 5, name: "تم تسليم العميل" },
  { id: 6, name: "تم الغاء الطلب" },
]

