import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import { baseurl, entryTypes } from "../../constants";
import axios from "axios";
import UpdateButton from "../../Components/Buttons/UpdateButton";
import DeleteButton from "../../Components/Buttons/DeleteButton";
import { deleteCountry, deleteData, editeData } from "../Core/Rotes";





export default function DataEntries() {
    const [data, setData] = useState();

    const navigate = useNavigate();






    const [loader, showLoader, hideLoader] = useLoader();

    //** get data  */
    const getData = async (type) => {
        showLoader();
        try {
            const response = await axios.get(baseurl + "dashboard/get-data", {
                params: { type: type },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"), // إضافة الـ Authorization header
                },
            });
            setData(response.data);
            hideLoader();
        } catch (err) {
            hideLoader();
            console.error(err + "kjjjjjjj");
        }
    };







    const initData = async () => {

        getData("not");


    };

    useEffect(() => {
        if (!data) {
            initData();
        }
    }, []);

    return (
        <>
            <div>
                {loader}
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="breadcrumb-title pe-3">البيانات</div>
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item">
                                    <a href="javascript:;">
                                        <i className="bx bx-home-alt" />
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    قائمة البيانات
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="ms-auto">

                    </div>
                </div>





                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <div
                                id="example2_wrapper"
                                className="dataTables_wrapper dt-bootstrap5"
                            >
                                <div className="row">
                                    <div className="col-sm-12">
                                        <p></p>

                                        <div className="col-4">
                                            <label className="form-label">اختر نوع البيان  </label>
                                            {entryTypes && (
                                                <select

                                                    required
                                                    // name="ServiceId"
                                                    onChange={async (value) => {


                                                        console.log(value.target.value);

                                                        await getData(value.target.value)



                                                    }}
                                                    id="school-id"
                                                    className="form-select"
                                                >
                                                    <option value={""}>اختر</option>
                                                    {entryTypes.map((row) => (
                                                        <option key={row.nameAr} value={row.nameEn}>
                                                            {row.nameEn}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}

                                            <span
                                                asp-validation-for="FieldId"
                                                className="text-danger"
                                            />
                                        </div>
                                        <br />

                                        <table
                                            id="DataTable"
                                            className="table table-striped table-bordered dataTable"
                                            role="grid"
                                            aria-describedby="example2_info"
                                        >
                                            <thead>
                                                <tr role="row">
                                                    <th
                                                        className="sorting_asc"
                                                        tabIndex={0}
                                                        aria-controls="example2"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                        aria-sort="ascending"
                                                        aria-label="Name: activate to sort column descending"
                                                        style={{ width: "40.828px" }}
                                                    >
                                                        <span className="mx-3">م</span>
                                                    </th>
                                                    <th
                                                        className="sorting_asc text-center align-middle"
                                                        tabIndex={0}
                                                        aria-controls="example2"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                        aria-sort="ascending"
                                                        aria-label="Name: activate to sort column descending"
                                                        style={{ width: "120.828px" }}
                                                    >
                                                        {" "}
                                                        الاســـم باللغة العربية{" "}
                                                    </th>
                                                    <th
                                                        className="sorting_asc text-center align-middle"
                                                        tabIndex={0}
                                                        aria-controls="example2"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                        aria-sort="ascending"
                                                        aria-label="Name: activate to sort column descending"
                                                        style={{ width: "120.828px" }}
                                                    >
                                                        {" "}
                                                        الاســـم باللغة الانجليزية{" "}
                                                    </th>



                                                    <th
                                                        className="sorting text-center align-middle"
                                                        tabIndex={0}
                                                        aria-controls="example2"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                        aria-label="Position: activate to sort column ascending"
                                                        style={{ width: "70.891px" }}
                                                    >
                                                        النوع{" "}
                                                    </th>

                                                    <th
                                                        className="sorting text-center align-middle"
                                                        tabIndex={0}
                                                        aria-controls="example2"
                                                        rowSpan={1}
                                                        colSpan={1}
                                                        aria-label="Position: activate to sort column ascending"
                                                        style={{ width: "80.891px" }}
                                                    >
                                                        تعديل
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data &&
                                                    data.map((item, index) => (
                                                        <tr role="row" className="odd">
                                                            <td className="text-center align-middle">{item.id}</td>
                                                            <td className="text-center align-middle">{item.nameAr}</td>

                                                            <td className="text-center align-middle">{item.nameEng}</td>
                                                            <td className="text-center align-middle">{item.typeAr}</td>

                                                            <td className="text-center align-middle">



                                                                <UpdateButton icon="bi bi-pencil-fill" onClick={() => {

                                                                    navigate(editeData, { state: { row: item } });
                                                                }} />

                                                                <DeleteButton icon="bi bi-trash-fill" onClick={() => {
                                                                    navigate(deleteData, { state: { row: item } });
                                                                }} />


                                                            </td>

                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                        <br />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}