import { useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import axios from "axios";
import {
  baseurl,
  entryTypes,
  OnInput,
  onInputInvalid,
  successNotify,
  uploadImage,
} from "../../constants";

export default function CreateData() {

  const [errors, setErrors] = useState({});
  const [nameAr, setNameAr] = useState();
  const [nameEn, setNameEn] = useState();
  const [type, setType] = useState();

  const [image, setImage] = useState("");
  const [route, setRoute] = useState("");
  const [loader, showLoader, hideLoader] = useLoader();



  const handleValidation = () => {
    let errors = [];
    let formIsValid = true;

    if (!nameAr) {
      formIsValid = false;

      errors["nameAr"] = "هذا الحقل مطلوب";
    }
    if (!nameEn) {
      formIsValid = false;

      errors["nameEn"] = "هذا الحقل مطلوب";
    }

    // if (!image) {
    //   formIsValid = false;

    //   errors["image"] = "هذا الحقل مطلوب";
    // }

    // setErrors(errors);

    return formIsValid;
  };

  useEffect(() => {

  }, []);

  const navigateTo = useNavigate();
  const postForm = async () => {
    showLoader();

    if (handleValidation() === false) return;



    // console.log(image);

    const formdata = new FormData();
    formdata.append("typeAr", type.nameAr);
    formdata.append("typeEng", type.nameEn);
    formdata.append("nameAr", nameAr);
    formdata.append("NameEng", nameEn);
    formdata.append("image", image);
    formdata.append("route", route);

    try {
      const response = await fetch(baseurl + "data/add-data-entry", {
        method: "POST",
        // headers: {
        //   Authorization: "Bearer " + localStorage.getItem("token"),
        // },
        body: formdata,
      });
      const json = await response.json().s;
      hideLoader();
      successNotify();
      console.log(json);
      navigateTo("/admin/data");
    } catch (error) {
      hideLoader();
      console.error(error + " ===== >errrrrr");
    }
  };


  // ** upload image
  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const resp = await axios
        .post(baseurl + "image/upload/image", formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((e) => {

          setImage(e.data);

          console.log(e.data);
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      {loader}
      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div className="breadcrumb-title pe-3">البيانات</div>
        <div className="ps-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item">
                <a href="javascript:;">
                  <i className="bx bx-home-alt" />
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                إضافة بيان
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/*end breadcrumb*/}
      <hr />
      <div className="col-xl-12 mx-auto text-start">
        <div className="card">
          <div className="card-body">
            <div className="border p-3 rounded">
              <h6 className="mb-0 text-uppercase">معلومات البيان</h6>
              <hr />
              <div
                asp-validation-summary="ModelOnly"
                className="text-danger "
              />

              <div className="col-4">
                <label className="form-label">اختر نوع البيان  </label>
                {entryTypes && (
                  <select

                    required
                    // name="ServiceId"
                    onChange={async (value) => {


                      // console.log(value.target.value);
                      setType(entryTypes.find((row) => row.nameEn === value.target.value));






                    }}
                    id="school-id"
                    className="form-select"
                  >
                    <option value={""}>اختر</option>
                    {entryTypes.map((row) => (
                      <option key={row.nameAr} value={row.nameEn}>
                        {row.nameEn}
                      </option>
                    ))}
                  </select>
                )}

                <span
                  asp-validation-for="FieldId"
                  className="text-danger"
                />
              </div>
              <br />
              <div className="row g-3">
                <div className="col-6">
                  <label className="form-label">القيمة باللغة العربية </label>
                  <input
                    onInvalid={onInputInvalid}
                    value={nameAr}
                    onChange={(event) => {
                      setNameAr(event.target.value);
                    }}
                    onInput={OnInput}
                    required
                    autoComplete="off"
                    name="Name"
                    type="text"
                    className="form-control"
                  />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>

                <div className="col-6">
                  <label className="form-label">القيمة باللغة الإنجليزية </label>
                  <input
                    onInvalid={onInputInvalid}
                    value={nameEn}
                    onChange={(event) => {
                      setNameEn(event.target.value);
                    }}
                    onInput={OnInput}
                    required
                    autoComplete="off"
                    name="NameEn"
                    type="text"
                    className="form-control"
                  />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>

                <div className="col-6">
                  <label className="form-label">رمزالصفحة</label>
                  <input
                    onInvalid={onInputInvalid}
                    // value={nameEn}
                    onChange={(event) => {
                      setRoute(event.target.value);
                    }}
                    onInput={OnInput}
                    // required
                    autoComplete="off"
                    name="Route"
                    type="text"
                    className="form-control"
                  />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>


                <div class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input"
                    id="customFile"
                    onChange={(value) => {
                      uploadImage(value.target.files[0]);
                    }}
                  />

                  <div className="col-12">
                    <div className="image col-6">
                      <img
                        src={image != null ? baseurl + "images/" + image : "public/assets/photo.jpeg"}
                        width={64}
                        height={64}
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12" onClick={postForm}>
                  <div className="d-grid">
                    <input
                      type="submit"
                      value="حفظ"
                      className="btn btn-primary"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
