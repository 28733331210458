import React from "react";

function TextButton({onClick , text}) {
  return (
    <button className="btn btn-primary m-1 text-center" style={{ fontSize: "14px" }} onClick={onClick}>
      {text}
    </button>
  );
}

export default TextButton;