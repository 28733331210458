
import RowDetails from '../../Components/RowDetails';
import { Link, useLocation, useNavigate } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import { baseurl, OnInput, onInputInvalid, successNotify, uploadImage } from "../../constants";
import { formatDateToText, listStatusOrder } from '../../Helpers/Utilities';


export default function DeleteOrder() {

  const location = useLocation();
    const row = location.state.row;
    const navigateTo = useNavigate()
    const [loader, showLoader, hideLoader] = useLoader(); 


    const postForm = async () => {
         showLoader()
        const formdata = new FormData()
        formdata.append("orderId", row.order.id)


        const response = await fetch(baseurl +"dashboard/delete-order", {
            method: "POST",
            headers: {

                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: formdata
        });
        console.log(response.status);

        hideLoader()

        successNotify()

        navigateTo("/admin/orders");

    };
 
  return <div>
    {loader}
    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
      <div className="breadcrumb-title pe-3">الطبات</div>
      <div className="ps-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0 p-0">
            <li className="breadcrumb-item">
              <a href="javascript:;">
                <i className="bx bx-home-alt" />
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">حذف الطلب</li>
          </ol>
        </nav>
      </div>
    </div>
    {/*end breadcrumb*/}
    <hr />
    <div className="col-xl-12 mx-auto">
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <h6 className="mb-0 text-uppercase">معلومات الطلب </h6>
            <hr />
              <div asp-validation-summary="ModelOnly" className="text-danger" />
              <div className="row g-3">

                <RowDetails name="رقم الطلب" value={row.order.id} />
                <RowDetails name="اسم العميل" value={row.user&&row.user.firstName} />
                <RowDetails name="تاريخ الطلب" value={formatDateToText(row.order.createdAt)} />
                <RowDetails name="حالة الطلب" value={listStatusOrder[row.order.status].name} />
            
              
            
              

                <div onClick={postForm} class="form-group">
                            <input type="hidden" asp-for="Id" />
                            <input type="submit" value=" تأكيد الحذف" class="btn btn-danger float-right" />
                    </div>
            
             
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>

}