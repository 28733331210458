

export const onboarding = "onboarding";

export const addOnboarding = "onboarding/add";
export const editeOnboarding = "onboarding/edite";
export const deleteOnboarding = "onboarding/delete";
export const addOnboardingAdmin = "/admin/onboarding/add";
export const editeOnboardingAdmin = "/admin/onboarding/edite";
export const deleteOnboardingAdmin = "/admin/onboarding/delete";

export const orders = "/admin/orders";
export const orderDetails = "/details";
export const deleteOrder = "/admin/orders/delete";

export const payMent = "/admin/users/payment";
export const createBrannd = "/admin/brand/create";
export const deleteBrand = "/admin/brands/delete";

export const createModel = "/admin/model/create";

export const deleteModel= "/admin/model/delete";
export const createCountry = "/admin/country/create";
export const editCountry = "/admin/country/edit";
export const deleteCountry= "/admin/coutry/delete";

export const createData = "/admin/data/create";
export const editeData = "/admin/data/edit";
export const deleteData= "/admin/data/delete";









