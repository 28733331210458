import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import useLoader from '../../Components/loader/useLoader';
import { useLocation, useNavigate } from 'react-router-dom';
import { baseurl } from '../../constants';
import RowDetails from '../../Components/RowDetails';
import { formatDateToText, getServices, listStatusOrder, listStatusOrderFurniture } from '../../Helpers/Utilities';
import Lable from '../../Components/Lable';
import PrintableComponent from './PrintOrder';
import ReactToPrint from "react-to-print";


const OrderDetails = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const { id, status } = location.state.row;
    const componentRef = useRef();
    const [data, setData] = useState(null);
    const [loader, showLoader, hideLoader] = useLoader();



    // ! get order details 
    const getOrderDetails = async (id) => {

        showLoader();


        var requestOptions = {
            method: "GET",

            redirect: "follow",
        };

        fetch(baseurl + 'orders/get-Orders-details?orderId=' + id, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                hideLoader();
                console.log(result);
                setData(result);
                return result;
            })
            .catch((error) => {
                hideLoader();
                console.log("error", error);
            });
    }

    // ! update order Staues
    const updateStatus = async (orderId, status) => {
        showLoader();
        try {
            var formdata = new FormData();
            formdata.append("orderId", orderId);
            formdata.append("status", status);

            const response = await fetch(baseurl + "dashboard/update-status-order", {
                body: formdata,
                method: "POST",

                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            const json = await response.json();
            console.log(json);

            hideLoader();
            // setData(json);
            return json;
        }

        catch (error) {
            hideLoader();
            console.error(error + "errrrrrrrr");
        }


    };


    useEffect(() => {

        console.log(id);

        if (data === null) {
            getOrderDetails(id);



        }

    }, [])


    const handlePrint = () => {
      
        window.print();
    };


    return (
        <div className="content-wrapper">
            {loader}

            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="breadcrumb-title pe-3">الطلبات</div>
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item">
                                <a href="javascript:;">
                                    <i className="bx bx-home-alt" />
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                تفاصيل الطلب
                            </li>
                        </ol>
                    </nav>
                </div>


            </div>
            {/*end breadcrumb*/}
            <hr />
            {data &&

                <>


                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <div
                                    id="example2_wrapper"
                                    className="dataTables_wrapper dt-bootstrap5"
                                >
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <p></p>
                                            <div className="row justify-content-start">





                                                <RowDetails name="رقم الطلب" value={data.order.id} />
                                                {/* change status */}
                                                <div className="col-4">
                                                    <label className="form-label">{"تعديل حالة الطلب"} </label>
                                                    <select

                                                        required
                                                        // name="ServiceId"
                                                        onChange={async (value) => {
                                                            //! update order status
                                                            console.log(value.target.value + "wefwwvefd");


                                                            await updateStatus(data.order.id, value.target.value);
                                                            await getOrderDetails(id);

                                                        }}
                                                        id="status-id"
                                                        className="form-select"
                                                    >
                                                        <option value={""}>تعديل حالة الطلب </option>
                                                        {data.order.typeStatus === 0 ? listStatusOrder.map((row) => (
                                                            <option key={row.id} value={row.id}>
                                                                {row.name}
                                                            </option>
                                                        )) : listStatusOrderFurniture.map((row) => (
                                                            <option key={row.id} value={row.id}>
                                                                {row.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <RowDetails name="حالة الطلب" value={listStatusOrder[data.order.status].name} />

                                                <RowDetails name="تاريخ الطلب" value={formatDateToText(data.order.createdAt)} />

                                                <Lable text="معلومات العميل" />
                                                {data.user && <>
                                                    <RowDetails name="الاسم الاول" value={data.user.firstName} />
                                                    <RowDetails name="الاسم الثاني" value={data.user.lastName} />
                                                    <RowDetails name="رقم الهاتف" value={data.user.phoneNumber} />
                                                    <RowDetails name="البريد الالكتروني" value={data.user.userName} />
                                                </>}
                                                <Lable text=" معلومات الطلب" />

                                                <RowDetails name="القسم" value={data.service.nameAr} />
                                                {data.dataEntries.map(e => <RowDetails name={e.typeAr} value={e.nameAr} />)}
                                                <RowDetails name="نوع الدراجة النارية" value={data.order.motoType} />
                                                {
                                                    <RowDetails name="الماركة" value={data.brand && data.brand.nameAr} />}
                                                <RowDetails name="الموديل" value={data.model && data.model.nameAr} />



                                                {data.order.isOwner && <RowDetails name="هل انت المالك" value={data.order.isOwner && data.order.isOwner ? "نعم" : "لا"} />}
                                                <RowDetails name="نوع المركبة" value={data.order.carType} />
                                                <RowDetails name="نوع الشحن" value={data.order.pakageTypeShipping} />
                                                <RowDetails name="نوع التأمين" value={data.order.insrance} />



                                                <RowDetails name="الوصف" value={data.order.desc} isDesc={true} />
                                                <RowDetails name="الوزن" value={data.order.wight} />
                                                <RowDetails name="المودل" value={data.order.heavyModel} />
                                                <RowDetails name="نوع التأمين" value={data.order.insrance} />
                                                <RowDetails name="سعة المجرك" value={data.order.engineCapacityMoto} />

                                                <RowDetails name="عنوان الارسال " value={data.order.entryAddress} />
                                                <RowDetails name="عنوان الاستقبال" value={data.order.arrivalAddress} />
                                                <RowDetails name="بلد الارسال " value={data.sendingCountry && data.sendingCountry.nameAr} />
                                                <RowDetails name="بلد الاستقبال" value={data.arrivingCountry && data.arrivingCountry.nameAr} />
                                                <RowDetails name="بلد الإقامة" value={data.countryResidence && data.countryResidence.nameAr} />
                                                <RowDetails name="بلد المنشأ" value={data.countryOrigin && data.countryOrigin.nameAr} />


                                                {/* sizes  */}
                                                {data.order.large && <h5 className="my-3"> الاحجام المطلوبة</h5>}
                                                <RowDetails name="كبير" value={data.order.large} />
                                                <RowDetails name="متوسط" value={data.order.meduim} />
                                                <RowDetails name="صغير" value={data.order.small} />

{/* print  */}
                                                <div className="col-12">
                                                    <button
                                                        onClick={handlePrint}
                                                        className="btn btn-primary"
                                                    >
                                                        طباعة
                                                    </button>
                                                </div>

                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            }
        </div>
    )




}


export default OrderDetails

