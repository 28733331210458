import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import validator from "validator";
import axios from "axios";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import {
  baseurl, successNotify, OnInput,
  onInputInvalid,
  entryTypes,
} from "../../constants";

export default function EditData() {
  const [data, setData] = useState();

  const [nameAr, setNameAr] = useState();
  const [nameEn, setNameEn] = useState();
  const [type, setType] = useState();

  const [image, setImage] = useState();
  const [route, setRoute] = useState("");
  const [serviceId, setServiceId] = useState();


  const [loader, showLoader, hideLoader] = useLoader();

  const navigateTo = useNavigate();



  // ! get data for edit
  const getDataForEdit = () => {
    if (row != null) {

      setNameAr(row.nameAr)
      setNameEn(row.nameEng)
      // setType(entryTypes.find(e => e.nameEn = row.typeEng))
      setRoute(row.route)
      setImage(row.image)

    }
  }


  ////*** upload image */
  const uploadImage = async (file, type) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const resp = await axios
        .post(baseurl + "image/upload/image", formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((e) => {
          console.log(e.data);

          setImage(e.data);

        });
    } catch (e) {
      console.log(e);
    }
  };

  const location = useLocation();
  const row = location.state.row;
  useEffect(() => {
    getDataForEdit();

  }, []);



  const postForm = async () => {
    console.log(image);
    showLoader();
    //  if(handleValidation()==false)return;
    try {
      const formdata = new FormData();
      formdata.append("typeAr",type!==null? type.nameAr:row.typeAr);
      formdata.append("typeEng",type!==null? type.nameEn:row.nameEng);
      formdata.append("nameAr", nameAr);
      formdata.append("NameEng", nameEn);
      formdata.append("Image", image);
      formdata.append("route", row.route);

      formdata.append(
        "id", row.id.toString()

      );


      const response = await fetch(baseurl + "data/update-data-entry", {
        method: "PUT",
        //   headers: {
        //     Authorization: "Bearer " + localStorage.getItem("token"),
        //   },
        body: formdata,
      });
      const json = await response.json();
      hideLoader();
      successNotify();


      console.log(json);
      navigateTo("/admin/data");
    } catch (error) {
      hideLoader();
      console.error(error + "kjjjjjjj");
    }
  };

  return (
    <div>
      {loader}
      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div className="breadcrumb-title pe-3">البيانات</div>
        <div className="ps-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item">
                <a href="javascript:;">
                  <i className="bx bx-home-alt" />
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                تعدبل البيان
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/*end breadcrumb*/}
      <hr />
      <div className="col-xl-12 mx-auto">
        <div className="card">
          <div className="card-body">
            <div className="border p-3 rounded">
              <h6 className="mb-0 text-uppercase">معلومات البيان</h6>
              <hr />
              <div asp-validation-summary="ModelOnly" className="text-danger" />
              <div className="row g-3">
                <div className="col-4">
                  <label className="form-label">اختر نوع البيان  </label>
                  <label className="form-label">اختر نوع البيان  </label>
                {entryTypes && (
                  <select

                    required
                    // name="ServiceId"
                    onChange={async (value) => {


                      // console.log(value.target.value);
                      setType(entryTypes.find((row) => row.nameEn === value.target.value));






                    }}
                    id="school-id"
                    className="form-select"
                  >
                    <option value={""}>اختر</option>
                    {entryTypes.map((row) => (
                      <option key={row.nameAr} value={row.nameEn}>
                        {row.nameEn}
                      </option>
                    ))}
                  </select>
                )}
                  <span
                    asp-validation-for="FieldId"
                    className="text-danger"
                  />
                </div>
                <br />

                <div className="col-6">
                  <label className="form-label">القيمة باللغة العربية </label>
                  <input
                    onInvalid={onInputInvalid}
                    value={nameAr}
                    onChange={(event) => {
                      setNameAr(event.target.value);
                    }}
                    onInput={OnInput}
                    required
                    autoComplete="off"
                    name="Name"
                    type="text"
                    className="form-control"
                  />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>

                <div className="col-6">
                  <label className="form-label">القيمة باللغة الإنجليزية </label>
                  <input
                    onInvalid={onInputInvalid}
                    value={nameEn}
                    onChange={(event) => {
                      setNameEn(event.target.value);
                    }}
                    onInput={OnInput}
                    required
                    autoComplete="off"
                    name="NameEn"
                    type="text"
                    className="form-control"
                  />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>

             

                <div class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input"
                    id="customFile"
                    onChange={(value) => {
                      uploadImage(value.target.files[0]);
                    }}
                  />

                  <div className="col-12">
                    <div className="image col-6">
                      <img
                        src={image != null ? baseurl + "images/" + image : baseurl + "images/" + row.imageUrl}
                        width={64}
                        height={64}
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12" onClick={postForm}>
                  <div className="d-grid">
                    <input
                      type="submit"
                      value="حفظ"
                      className="btn btn-primary"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
