import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import useLoader from "../../Components/loader/useLoader";
import {
  baseurl,
  OnInput,
  onInputInvalid,
  successNotify,
  uploadImage,
} from "../../constants";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
export default function CreateService() {
  const [loader, showLoader, hideLoader] = useLoader();
  let componentMounted = true;
  let endPointAddField = baseurl + "servies/add-Service";
  let endPointUpdateCategory = baseurl + "servies/update-Service";

  const [typeField, setTypeField] = useState("0");


  const [file, setFile] = useState(null);
  const [fileLogo, setFileLogo] = useState(null);
  const [titleEng, setTitleEng] = useState("");
  const [title, setTitle] = useState("");

  const [order, setOrder] = useState("");
  const location = useLocation();

  var [image, setImage] = useState();
  var [logo, setLogo] = useState();

  const options = ["للرجال والنساء معا ", "مقسم رجال ونساء"];
  const defaultOption = options[0];

  const navigate = useNavigate();
  const navigateHome = () => {
    // 👇️ navigate to /
    navigate("/admin/services");
  };


  const handleSubmit = async (event) => {
    console.log("image == " + image + "logo == " + logo);
    showLoader();
    event.preventDefault();

    if (location.state == null) {
      addCategory();
    } else {
      UpdateCategory(location.state.row.imageUrl);

    }
  };


  const uploadImage = async (file, type) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const resp = await axios
        .post(baseurl + "image/upload/image", formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((e) => {
          console.log(e.data + "uploadImage");
          if (type === 0) {
            //!  images
            setImage(e.data);
          } else {
            // logo 
            setLogo(e.data);
          }

        });
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  const getStatus = () => {
    if (typeField == options[0]) {
      return "0";

    } else if (typeField == options[1]) {
      return "1";
    } else {
      return "0";
    }
  }

  useEffect(() => {
    if (location.state != null) {
      const row = location.state.row;

      setTitle(row.nameAr);
      setTitleEng(row.nameEng);
      setImage(location.state.row.imageUrl);
      setTypeField(options[row.status])
      setOrder(row.order)
    }
  }, []);
  // *** add Category
  const addCategory = async () => {



    console.log("add");
    // Using Fetch API

    let data = new FormData();
    data.append("NameAr", title);
    data.append("NameEng", titleEng);
    data.append("ImageUrl", image);
    data.append("Logo", logo);
    data.append("Status", getStatus());
    data.append("Order", order);
    var requestOptions = {
      method: "POST",
      body: data,
      redirect: "follow",
    };

    fetch(endPointAddField, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        hideLoader();
        console.log(result);
        successNotify();
        setFile("");
        navigateHome();
      })
      .catch((error) => console.log("error", error));
  };


  // ** update Category

  const UpdateCategory = async (imageCategory) => {
    console.log("add");
    // Using Fetch API

    let data = new FormData();
    data.append("NameAr", title);
    data.append("NameEng", titleEng);
    data.append("Status", getStatus());
    data.append("Order", order);
    data.append("logo", logo ?? location.state.row.logo);
    data.append("ImageUrl", image ?? location.state.row.logo);
    data.append("id", location.state.row.id);

    var requestOptions = {
      method: "PUT",
      body: data,
      redirect: "follow",
    };

    fetch(endPointUpdateCategory, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        hideLoader();
        console.log(result);
        successNotify();
        setFile("");
        navigateHome();
      })
      .catch((error) => {
        hideLoader();
        console.log("error", error);
      });
  };


  return (
    <div>
      {loader}
      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div className="breadcrumb-title pe-3">التصنيفات</div>
        <div className="ps-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item">
                <a href="javascript:;">
                  <i className="bx bx-home-alt" />
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {location.state != null ? "تعديل التصنيف" : "اضافة تصنيف"}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/*end breadcrumb*/}
      <hr />
      <div className="col-xl-12 mx-auto">
        <div className="card">
          <div className="card-body">
            <div className="border p-3 rounded">
              <h6 className="mb-0 text-uppercase">{location.state != null ? "تعديل التصنيف" : "اضافة تصنيف"}</h6>
              <hr />
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div
                  asp-validation-summary="ModelOnly"
                  className="text-danger"
                />
                <div className="row g-3">
                  <div className="col-6">
                    <label className="form-label">
                      الاســـم باللغة العربية{" "}
                    </label>
                    <input
                      onInvalid={onInputInvalid}
                      value={title}
                      onChange={(event) => {
                        setTitle(event.target.value);
                      }}
                      onInput={OnInput}
                      required
                      autoComplete="off"
                      name="Name"
                      type="text"
                      className="form-control"
                    />
                    <span asp-validation-for="Name" className="text-danger" />
                  </div>

                  <div className="col-6">
                    <label className="form-label">
                      الإسم باللغة الإنجليزية{" "}
                    </label>
                    <input
                      onInvalid={onInputInvalid}
                      value={titleEng}
                      onChange={(event) => {
                        setTitleEng(event.target.value);
                      }}
                      onInput={OnInput}
                      required
                      autoComplete="off"
                      name="NameEn"
                      type="text"
                      className="form-control"
                    />
                    <span asp-validation-for="Name" className="text-danger" />
                  </div>

                  <div className="col-6">
                    <label className="form-label">
                      الترتيب{" "}
                    </label>
                    <input
                      onInvalid={onInputInvalid}
                      value={order}
                      onChange={(event) => {
                        setOrder(event.target.value);
                      }}
                      onInput={OnInput}
                      required
                      autoComplete="off"
                      name="Order"
                      type="number"
                      className="form-control"
                    />
                    <span asp-validation-for="Name" className="text-danger" />
                  </div>






                  <div className="col-12">

                    <div className="col-6">
                      <label >

                        اختار نوع التصنيف
                      </label>
                      <Dropdown className="m-3"
                        options={options}

                        onChange={(value) => {
                          console.log(value.value);
                          setTypeField(value.value);

                        }}
                        value={location.state != null ? options[location.state.row.status] : defaultOption}
                        placeholder="اختار"
                      />

                    </div>
                  </div>

                </div>

                {/*  images  */}
                <div class="col-12">

                  {/*  logo */}
                  <div className="col-4">
                    <label className="form-label">
                      الشعار{" "}
                    </label>
                    <div class="custom-file">
                      <input
                        type="file"
                        class="custom-file-input"
                        id="customFile"
                        onChange={(e) => {
                          setFileLogo(e.target.files[0]);
                          uploadImage(e.target.files[0], 1)
                        }}
                      />
                      <div className="image col-6">
                        <img
                          src={
                            fileLogo
                              ? URL.createObjectURL(fileLogo)
                              : location.state
                                ? baseurl + "images/" + logo
                                : "./assets/photo.jpeg"
                          }
                          width={64}
                          height={64}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  {/*  photo */}
                  <div className="col-4">
                    <label className="form-label">
                      الصورة{" "}
                    </label>
                    <div class="custom-file">
                      <input
                        type="file"
                        class="custom-file-input"
                        id="customFile"
                        onChange={(e) => {
                          setFile(e.target.files[0])
                          uploadImage(e.target.files[0], 0)
                        }}
                      />
                      <div className="image col-6">
                        <img
                          src={
                            file
                              ? URL.createObjectURL(file)
                              : location.state
                                ? baseurl + "images/" + image
                                : "./assets/photo.jpeg"
                          }
                          width={64}
                          height={64}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-grid">
                    <input
                      // onInvalid={onInputInvalid}
                      // onInput={OnInput}
                      required
                      type="submit"
                      onClick={() => {
                        console.log("image == " + image + "logo == " + logo);
                      }}
                      value="حفظ"
                      className="btn btn-primary"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
