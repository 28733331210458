
import { baseurl, errorNotify, OnInput, onInputInvalid, successNotify } from '../../constants'
import { useLocation, useNavigate } from 'react-router-dom';
import useLoader from '../../Components/loader/useLoader';
import { useState } from 'react';

const SendLinkPayment = () => {
    const location = useLocation();
    const navigateTo = useNavigate();

    const [loader, showLoader, hideLoader] = useLoader();
    const { id, } = location.state.row;
    const [link , setLink] = useState('');



    const sendLink = async (e) => {
        // console.log(e.target.link.value);

        showLoader();
        e.preventDefault();
        const formData = new FormData();
        formData.append('link',link);
        formData.append('userId', id);
        try {
            const response = await fetch(baseurl + "dashboard/send-payment-link", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },

                body: formData,
            });
            const json = await response.json();
            hideLoader();

            console.log(json);
            successNotify();
            navigateTo("/admin/users");
            return json;
        } catch (error) {
            hideLoader();
            errorNotify();
            console.error(error + "kjjjjjjj");
        }

    }



    return (
        <>{loader}
            <div className="">
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="breadcrumb-title pe-3">المستخدمين</div>
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item">
                                    <a href="javascript:;">
                                        <i className="bx bx-home-alt" />
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    ارسال رابط الدفع
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <hr />

                <div className="col-6">
                    <label className="form-label">
                        أدخل رابط الدفع{" "}
                    </label>
                    <input
                        onInvalid={onInputInvalid}
                        onInput={OnInput}
                        required
                        onChange={(e) => setLink(e.target.value)}
                        autoComplete="off"
                        name="NameEng"
                        type="text"
                        className="form-control"
                    />
                    <span asp-validation-for="NameEn" className="text-danger" />
                </div>


                <div className="col-6 mt-4">
                    <div className="d-grid">
                        <input
                            onInvalid={onInputInvalid}
                            onInput={OnInput}
                            required
                            onClick={sendLink}
                            type="submit"
                            value="حفظ"
                            className="btn btn-primary"
                        />
                    </div>
                </div>
            </div>

        </>
    )
}

export default SendLinkPayment
